












import {UserStat} from "@/request/data-center/UserStat";
import {Vue, Component} from "vue-property-decorator";

@Component({
    name: 'data_userStat'
})
export default class data_userStat extends Vue {
    modal: any = UserStat;
    search: Array<any> = [
        {
            type: 'RadioGroup',
            label: '查看方式',
            key: 'searchType',
            value: 0,
            onchange: (status: any) => {
                let datePicker: any;
                switch (status) {
                    case 0:
                        datePicker = {
                            type: 'DatePicker',
                            pickerType: 'daterange',
                            label: '日期',
                            value: '',
                            width: '220px',
                            require: true,
                            placeholder: '请选择日期'
                        }
                        break;
                    case 1:
                        datePicker = {
                            type: 'DatePicker',
                            pickerType: 'month',
                            label: '日期',
                            key: 'months',
                            value: '',
                            width: '220px',
                            multiple: true,
                            require: true,
                            placeholder: '请选择日期'
                        }
                        break;
                }
                this.search.splice(this.search.length - 1, 1, datePicker);
            },
            items: [
                {
                    label: '天',
                    value: 0
                },
                {
                    label: '月',
                    value: 1
                }
            ]
        },
        {
            type: 'DatePicker',
            pickerType: 'daterange',
            label: '日期',
            value: '',
            width: '220px',
            require: true,
            placeholder: '请选择日期'
        }
    ];
    columns: Array<any> = [
        {
            title: '日期',
            key: 'dateStr'
        },
        {
            title: '活跃人数',
            key: 'activeNum'
        },
        {
            title: '新增人数',
            key: 'newNum'
        },
        {
            title: '交易人数',
            key: 'transNum'
        }
    ]
}

